//import 'babel/polyfill';

const blendColors = [
    {
        className: 'magenta',
        bgColor: 'rgba(107, 0, 68, 0.6)',
        fontColor: 'rgba(107, 0, 68, 1)',
    },
    {
        className: 'orange',
        bgColor: 'rgba(107, 18, 0, 0.6)',
        fontColor: 'rgba(107, 18, 0, 1)',
    },
    {
        className: 'purple',
        bgColor: 'rgba(117, 18, 180, 0.6)',
        fontColor: 'rgba(117, 18, 180, 1)',
    },
    {
        className: 'turkey',
        bgColor: 'rgba(0, 210, 210, 0.6)',
        fontColor: 'rgba(0, 210, 210, 1)',
    },
    {
        className: 'gray',
        bgColor: 'rgba(111, 111, 111, 0.6)',
        fontColor: 'rgba(111, 111, 111, 1)',
    }
];

const heroBlend = document.querySelector('.hero__content');
const socialLinks = document.querySelectorAll('.social__link');
const socialIcons = document.querySelectorAll('.social__icon');

function setColor() {
    const index = Math.floor(Math.random() * blendColors.length);
    const color = blendColors[index];

    heroBlend.style.backgroundColor = color.bgColor;
    socialIcons.forEach(icon => icon.style.color = color.fontColor);

}

( function init() {
    setColor();

    anime({
        targets: document.querySelector('.hero__headline'),
        easing: 'easeInOutQuad',
        opacity: 1,
        duration: 1000
    });

    anime({
        targets: document.querySelector('.hero__subheadline'),
        easing: 'easeInOutQuad',
        opacity: 1,
        delay: 500,
        duration: 1000
    });

    let linkDelay = 1000;
    socialLinks.forEach(link => {
        anime({
            targets: link,
            duration: 800,
            easing: 'easeInOutQuad',
            translateY: {
                value: -500,
                delay: linkDelay
            }
        });

        linkDelay = linkDelay + 200;
    });

    anime({
        targets: document.querySelector('.copyright'),
        easing: 'easeInOutQuad',
        opacity: 1,
        delay: 2000,
        duration: 1000
    });
} )();
